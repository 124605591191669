import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconTwitter(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5 0-.279-.028-.556-.08-.83A7.72 7.72 0 0023 3z", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconTwitter.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconTwitter;
