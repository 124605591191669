var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { mapResponsive } from '../../utils/styled';
import { Box } from '../Box';
import { Grid } from '../Grid';
export const LayoutGrid = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (React.createElement(Grid, Object.assign({ columns: [4, 4, 8, 12], equalColumns: true, gap: ['gutterMobile', 'gutterTablet', 'gutterDesktop'] }, rest), children));
};
export const LayoutGridItem = (_a) => {
    var { span, push, children } = _a, rest = __rest(_a, ["span", "push", "children"]);
    return (React.createElement(React.Fragment, null,
        push && (React.createElement(Box, { gridColumn: mapResponsive(push, (value) => (value === 0 ? '' : `auto / span ${value}`)), display: mapResponsive(push, (value) => (value === 0 ? 'none' : 'initial')) })),
        React.createElement(Box, Object.assign({ gridColumn: mapResponsive(span, (value) => `auto / span ${value}`) }, rest), children)));
};
