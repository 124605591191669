import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { OverlayContainer, useModal, useOverlay, usePreventScroll } from '@react-aria/overlays';
import * as React from 'react';
import { IconX } from '../../icon';
import { Box } from '../Box';
import { Button } from '../Button';
import { Card } from '../Card';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
const DialogInner = ({ children, isOpen, onClose, width = 'dialog', icon: IconComp, title }) => {
    const ref = React.useRef(null);
    const { overlayProps, underlayProps } = useOverlay({ isOpen, onClose, isDismissable: true }, ref);
    usePreventScroll();
    const { modalProps } = useModal();
    const { dialogProps } = useDialog({}, ref);
    return (React.createElement(Box, Object.assign({ position: "fixed", zIndex: "overlay", top: 0, left: 0, bottom: 0, right: 0, bg: "rgba(0, 0, 0, 0.7)", display: "flex", alignItems: "center", justifyContent: "center" }, underlayProps),
        React.createElement(FocusScope, { contain: true, restoreFocus: true, autoFocus: true },
            React.createElement(Card, Object.assign({ position: "relative", px: 3, pt: 3, pb: 4, width: width, maxWidth: "100%" }, overlayProps, dialogProps, modalProps, { ref: ref }),
                React.createElement(Stack, { gap: 3 },
                    React.createElement(Shelf, { gap: 2 },
                        IconComp && (isComponent(IconComp) ? React.createElement(IconComp, { size: "iconMedium" }) : IconComp),
                        React.createElement(Text, { variant: "heading2" }, title),
                        React.createElement(Button, { variant: "tertiary", icon: IconX, onClick: () => onClose(), style: { marginLeft: 'auto' } })),
                    children)))));
};
export const Dialog = (props) => {
    return props.isOpen ? (React.createElement(OverlayContainer, null,
        React.createElement(DialogInner, Object.assign({}, props)))) : null;
};
function isComponent(object) {
    return typeof object === 'function';
}
