var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import shouldForwardProp from '@styled-system/should-forward-prop';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { color, compose, typography } from 'styled-system';
const StyledText = styled('span').withConfig({
    shouldForwardProp: (prop) => shouldForwardProp(prop),
})({ margin: 0 }, compose(typography, color));
const TextContext = React.createContext(false);
function useTextContext() {
    return React.useContext(TextContext);
}
const Text = (props) => {
    const isInText = useTextContext();
    const theme = useTheme();
    let textProps = props;
    if (props.variant) {
        textProps = Object.assign(Object.assign({}, theme.typography[props.variant]), props);
    }
    const { 
    // variant,
    children, as = isInText ? 'span' : 'div', color = isInText ? 'inherit' : 'textPrimary', fontSize = isInText ? 'inherit' : '1rem', fontWeight = isInText ? 'inherit' : 400, lineHeight = isInText ? 'inherit' : 1.5, fontFamily = isInText ? 'inherit' : 'standard' } = textProps, rest = __rest(textProps, ["children", "as", "color", "fontSize", "fontWeight", "lineHeight", "fontFamily"]);
    const textDecoration = props.underline ? 'underline' : 'initial';
    const overflow = props.textOverflow ? { overflow: 'hidden', textOverflow: props.textOverflow } : {};
    return (React.createElement(TextContext.Provider, { value: true },
        React.createElement(StyledText, Object.assign({ as: as, color: color, fontSize: fontSize, fontWeight: fontWeight, lineHeight: lineHeight, fontFamily: fontFamily, style: Object.assign({ textDecoration }, overflow) }, rest), children)));
};
export { Text, useTextContext, TextContext };
