import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconFaq(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { x: 2, y: 20, width: 20, height: 2, rx: 1, fill: "currentcolor" }),
        React.createElement("rect", { x: 2, y: 2, width: 20, height: 2, rx: 1, fill: "currentcolor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3 7a1 1 0 00-1 1v8a1 1 0 102 0v-3h2a1 1 0 100-2H4V9h3a1 1 0 000-2H3zm8.454.702a1 1 0 00-1.908 0l-2.5 8a1 1 0 001.908.596L9.36 15h2.28l.405 1.298a1 1 0 001.91-.596l-2.5-8zm-.954 3.65L11.015 13h-1.03l.515-1.647zm8.332 2.093a1 1 0 00-1.664 1.11l.183.275a2.086 2.086 0 01-.672-.602C16.278 13.694 16 12.91 16 12c0-.91.278-1.694.679-2.228.4-.535.878-.772 1.321-.772.443 0 .92.237 1.321.772.401.534.679 1.319.679 2.228 0 .896-.27 1.671-.661 2.205l-.507-.76zm1.655 2.482c.157-.155.302-.322.434-.499C21.606 14.515 22 13.3 22 12c0-1.3-.394-2.515-1.079-3.428C20.236 7.658 19.214 7 18 7c-1.214 0-2.236.658-2.921 1.572C14.394 9.485 14 10.7 14 12c0 1.3.394 2.515 1.079 3.428C15.764 16.342 16.786 17 18 17c.256 0 .504-.03.742-.084l.426.639a1 1 0 001.664-1.11l-.345-.518z", fill: "currentcolor" })));
}
SvgIconFaq.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconFaq;
