var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip';
import { useTooltipTriggerState } from '@react-stately/tooltip';
import * as React from 'react';
import styled from 'styled-components';
import { Positioner } from '../Positioner';
import { Stack } from '../Stack';
import { Text } from '../Text';
const StyledTrigger = styled(Text) `
  padding: 0;
  border: 0;
  appearance: none;
  background: transparent;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.standard};
  position: relative;
  width: fit-content;

  ::after {
    position: absolute;
    content: '';
    height: 1px;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: transparent;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.textSecondary};
  }
`;
export const Tooltip = (_a) => {
    var { title, body, children, disabled, delay = 1000 } = _a, textProps = __rest(_a, ["title", "body", "children", "disabled", "delay"]);
    const triggerRef = React.useRef(null);
    const overlayRef = React.useRef(null);
    const config = { isDisabled: disabled, delay };
    const state = useTooltipTriggerState(config);
    const { triggerProps, tooltipProps } = useTooltipTrigger(config, state, triggerRef);
    const { tooltipProps: tooltipElementProps } = useTooltip(tooltipProps, state);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTrigger, Object.assign({ as: "button", type: "button", ref: triggerRef }, triggerProps, textProps), children),
        state.isOpen && (React.createElement(Positioner, { isShown: true, targetRef: triggerRef, overlayRef: overlayRef, render: (positionProps) => (React.createElement(Stack, Object.assign({}, tooltipElementProps, positionProps, { ref: overlayRef, backgroundColor: "backgroundInverted", p: 1, borderRadius: "tooltip", width: 220 }),
                React.createElement(Text, { variant: "label2", color: "textInverted" }, title),
                React.createElement(Text, { variant: "body3", color: "textInverted" }, body))) }))));
};
