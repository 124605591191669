import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconBrandSpinner(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M19.071 19.071c3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0M12 19a7 7 0 100-14M9.172 14.828a4 4 0 105.656-5.656", stroke: "currentcolor", strokeWidth: 2 })));
}
SvgIconBrandSpinner.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconBrandSpinner;
