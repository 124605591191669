var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconChevronDown, IconChevronRight, IconChevronUp } from '../../icon';
import { Box } from '../Box';
import { VisualButton } from '../Button';
import { Card } from '../Card';
import { Shelf } from '../Shelf';
import { Text } from '../Text';
export const InteractiveCard = (_a) => {
    var { variant = 'default', icon, title, titleAddition, secondaryHeader, children, onClick } = _a, rest = __rest(_a, ["variant", "icon", "title", "titleAddition", "secondaryHeader", "children", "onClick"]);
    const [hovered, setHovered] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    function handleClick(e) {
        if (variant === 'collapsible') {
            setOpen((prev) => !prev);
        }
        if (onClick)
            onClick(e);
    }
    return (React.createElement(Card, Object.assign({ variant: variant === 'default' ? 'default' : 'interactive' }, rest),
        React.createElement(Header, { p: 2, as: variant !== 'default' ? 'button' : 'div', type: "button", onClick: handleClick, onMouseOver: () => setHovered(true), onMouseOut: () => setHovered(false), justifyContent: "space-between", width: "100%" },
            React.createElement(Shelf, { gap: 1 },
                icon,
                React.createElement(Shelf, { gap: 1, rowGap: 0, alignItems: "baseline", flexWrap: "wrap" },
                    React.createElement(Text, { variant: "heading2", color: variant === 'default' ? 'textPrimary' : 'textInteractive' }, title),
                    React.createElement(Text, { variant: "body2" }, titleAddition))),
            React.createElement(Box, { my: "-10px" },
                React.createElement(VisualButton, { variant: "tertiary", active: hovered, icon: variant === 'default'
                        ? undefined
                        : variant === 'button'
                            ? IconChevronRight
                            : open
                                ? IconChevronUp
                                : IconChevronDown }))),
        secondaryHeader && (React.createElement(Box, { p: 2, style: {
                boxShadow: `0 -1px 0 ${theme.colors.borderSecondary}`,
            } }, secondaryHeader)),
        (variant !== 'collapsible' || open) && children && (React.createElement(Box, { p: 2, backgroundColor: variant === 'collapsible' ? 'backgroundSecondary' : undefined, borderBottomLeftRadius: "card", borderBottomRightRadius: "card", style: {
                boxShadow: `0 -1px 0 ${theme.colors.borderSecondary}`,
            } }, children))));
};
const Header = styled(Shelf) `
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;
