import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconHome(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.926 6.667l4-4.001 4.002 4h-.001v6.668h-8V6.667h-.001zM2.593 7.999l-.984.985-.942-.942 6.318-6.318c.52-.52 1.363-.52 1.884 0l6.318 6.318-.942.942-.985-.985v5.335c0 .736-.597 1.333-1.333 1.333h-8a1.333 1.333 0 01-1.334-1.333V7.999z", fill: "currentcolor" })));
}
SvgIconHome.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconHome;
