import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconDiscord(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M14.014 1.907c.741.128 1.493-.338 1.66-1.095 1.196.072 2.934.483 4.053 1.647a.056.056 0 01.007.01c1.131 1.819 3.306 6.465 3.458 11.227.002.044-.015.064-.02.068-.918.833-3.016 2.242-5.84 2.777-.043.008-.113-.01-.158-.089l-.514-.9c-.41-.716-1.22-1.027-1.961-.904-1.176.196-3.225.343-5.561-.022a1.837 1.837 0 00-1.943 1.003l-.372.769a.178.178 0 01-.08.084.104.104 0 01-.075.008c-1.434-.378-3.8-1.25-5.766-2.724a.138.138 0 01-.049-.13 43.43 43.43 0 011.042-5.49c.542-2.09 1.272-4.16 2.197-5.647a.117.117 0 01.005-.008c.54-.463 1.994-1.354 4.034-1.618.22.742.986 1.145 1.692 1.018.928-.166 2.471-.283 4.191.016z", stroke: "currentcolor", strokeWidth: 1.6, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M5.214 12.79a.75.75 0 10-.502 1.413l.502-1.414zM19.3 14.198a.75.75 0 00-.524-1.406l.524 1.406zm-14.587.004c3.132 1.112 7.891 2.494 14.587-.004l-.524-1.406c-6.225 2.323-10.586 1.053-13.56-.004l-.503 1.414z", fill: "currentcolor" }),
        React.createElement("path", { d: "M9.849 8.718c0 1.002-.711 1.679-1.43 1.679-.718 0-1.43-.677-1.43-1.68 0-1.002.712-1.679 1.43-1.679.719 0 1.43.677 1.43 1.68zM17.01 8.718c0 1.002-.71 1.679-1.429 1.679-.718 0-1.43-.677-1.43-1.68 0-1.002.712-1.679 1.43-1.679s1.43.677 1.43 1.68z", stroke: "currentcolor", strokeWidth: 1.4 })));
}
SvgIconDiscord.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconDiscord;
