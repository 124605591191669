import { OverlayContainer, useOverlayPosition } from '@react-aria/overlays';
import * as React from 'react';
import { useTheme } from 'styled-components';
import { TextContext } from '../Text';
const PositionerInner = ({ isShown, targetRef, overlayRef, placement = 'top', offset = 1, render, }) => {
    var _a;
    const theme = useTheme();
    const { overlayProps } = useOverlayPosition({
        targetRef,
        overlayRef,
        placement,
        offset: (_a = theme.space[offset]) !== null && _a !== void 0 ? _a : offset,
        isOpen: isShown,
    });
    return render(overlayProps);
};
export const Positioner = (props) => {
    return props.isShown ? (React.createElement(OverlayContainer, null,
        React.createElement(TextContext.Provider, { value: false },
            React.createElement(PositionerInner, Object.assign({}, props))))) : null;
};
