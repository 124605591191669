var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Identicon from '@polkadot/react-identicon';
import * as React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Shelf } from '../Shelf';
import { Text } from '../Text';
import { VisualButton } from './VisualButton';
const StyledButton = styled.button({
    display: 'inline-block',
    width: '100%',
    padding: '0',
    border: 'none',
    appearance: 'none',
    background: 'transparent',
    outline: '0',
    whiteSpace: 'nowrap',
});
const IdenticonWrapper = styled(Flex)({
    pointerEvents: 'none',
});
export const WalletButton = (_a) => {
    var { iconStyle = 'polkadot', small = true, disabled, loading, loadingMessage = 'Connecting...', active, connectLabel = 'Connect wallet', address, alias, balance } = _a, buttonProps = __rest(_a, ["iconStyle", "small", "disabled", "loading", "loadingMessage", "active", "connectLabel", "address", "alias", "balance"]);
    return (React.createElement(StyledButton, Object.assign({ type: "button", disabled: loading || disabled }, buttonProps),
        React.createElement(VisualButton, { variant: "wallet", small: small, icon: address ? (React.createElement(IdenticonWrapper, null,
                React.createElement(Identicon, { value: address, size: 24, theme: iconStyle }))) : undefined, disabled: disabled, loading: loading, loadingMessage: loadingMessage, active: active },
            address && alias ? (React.createElement(Box, { position: "relative", flex: "1 1 auto" },
                React.createElement(Shelf, { position: "absolute", top: "0", bottom: "0", left: "0", width: "100%", m: "auto", height: "30px" },
                    React.createElement(Text, { fontSize: small ? 14 : 16, color: "inherit", fontWeight: 500, style: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        } }, alias)))) : (React.createElement(Text, { fontSize: small ? 14 : 16, color: "inherit", fontWeight: 500, style: { margin: address ? 0 : 'auto' } }, address ? truncate(address) : connectLabel)),
            address && balance && (React.createElement(Text, { variant: "body3", color: "inherit", style: { marginLeft: 'auto' } }, balance)))));
};
export function truncate(string) {
    const first = string.slice(0, 5);
    const last = string.slice(-5);
    return `${first}...${last}`;
}
