var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import css from '@styled-system/css';
import * as React from 'react';
import styled from 'styled-components';
import { VisualButton } from './VisualButton';
const StyledAnchor = styled.a(css({
    display: 'inline-block',
    textDecoration: 'none',
    outline: '0',
}), (props) => props.$disabled && { pointerEvents: 'none' });
export const AnchorButton = (_a) => {
    var { variant, small, icon, iconRight, disabled, loading, loadingMessage, children, active } = _a, anchorProps = __rest(_a, ["variant", "small", "icon", "iconRight", "disabled", "loading", "loadingMessage", "children", "active"]);
    return (React.createElement(StyledAnchor, Object.assign({ "$disabled": loading || disabled, rel: "noopener noreferrer" }, anchorProps),
        React.createElement(VisualButton, { variant: variant, small: small, icon: icon, iconRight: iconRight, disabled: disabled, loading: loading, loadingMessage: loadingMessage, active: active }, children)));
};
