import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconNftThumbnail(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M11.5.289a1 1 0 011 0l9.392 5.422a1 1 0 01.5.866v10.846a1 1 0 01-.5.866L12.5 23.71a1 1 0 01-1 0L2.108 18.29a1 1 0 01-.5-.866V6.577a1 1 0 01.5-.866L11.5.29z", fill: "currentcolor" }),
        React.createElement("path", { d: "M9.42 9.176v3.726L6.294 9.176h-.962v5.648H6.48V11.2l3.13 3.625h.96V12.63h2.739v-1.036h-2.739v-1.382h5.07v4.612h1.15v-4.612h1.878V9.176H9.419z", fill: "var(--secondary, white)" })));
}
SvgIconNftThumbnail.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconNftThumbnail;
