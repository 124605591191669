var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
export const Checkbox = (_a) => {
    var { label, errorMessage } = _a, checkboxProps = __rest(_a, ["label", "errorMessage"]);
    return (React.createElement("label", null,
        React.createElement(Shelf, { as: Text, gap: 1, alignItems: "baseline" },
            React.createElement(StyledWrapper, { minWidth: "18px", height: "18px", flex: "0 0 18px", "$hasLabel": !!label },
                React.createElement(StyledCheckbox, Object.assign({ type: "checkbox" }, checkboxProps)),
                React.createElement(StyledOutline, null)),
            label && (React.createElement(Stack, { gap: 1, flex: 1 },
                React.createElement(Text, { variant: "body1", color: checkboxProps.disabled ? 'textDisabled' : 'textPrimary' }, label),
                errorMessage && (React.createElement(Text, { variant: "label2", color: "statusCritical" }, errorMessage))))),
        !label && errorMessage && (React.createElement(Box, { mt: 1 },
            React.createElement(Text, { variant: "label2", color: "statusCritical" }, errorMessage)))));
};
const StyledOutline = styled.span `
  display: none;
  pointer-events: none;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  width: auto;
  height: auto;
  margin: auto;
  border: 2px solid var(--fabric-color-focus);
  border-radius: 4px;
`;
const StyledWrapper = styled(Flex) `
  position: relative;

  &::before {
    content: '.';
    width: 0;
    visibility: hidden;
  }
`;
const StyledCheckbox = styled.input `
  width: 18px;
  height: 18px;
  align-self: center;
  margin: -20px 0;

  &:focus-visible + span {
    display: block;
  }
`;
