import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconArrowUpRight(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#icon-arrow-up-right_svg__clip0)", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" },
            React.createElement("path", { d: "M7 17L17 7M7 7h10v10" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "icon-arrow-up-right_svg__clip0" },
                React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
}
SvgIconArrowUpRight.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconArrowUpRight;
