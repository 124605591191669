import * as React from 'react';
import styled from 'styled-components';
import { IconAlertCircle, IconCheckInCircle, IconInfo, IconInfoFailed } from '../../icon';
import { Flex } from '../Flex';
import { Shelf } from '../Shelf';
import { Text } from '../Text';
const icons = {
    default: IconInfo,
    info: IconInfo,
    ok: IconCheckInCircle,
    warning: IconAlertCircle,
    critical: IconInfoFailed,
};
export const InlineFeedback = ({ status = 'default', children }) => {
    return (React.createElement(Text, { variant: "body3" },
        React.createElement(Shelf, { alignItems: "baseline", gap: "4px" },
            React.createElement(StyledIconWrapper, { minWidth: "iconSmall", height: "iconSmall", flex: "0 0 auto" },
                React.createElement(StyledIcon, { as: icons[status], size: "iconSmall" })),
            React.createElement(Text, null, children))));
};
const StyledIconWrapper = styled(Flex) `
  &::before {
    content: '.';
    width: 0;
    align-self: center;
    visibility: hidden;
  }
`;
const StyledIcon = styled.div `
  align-self: center;
`;
