var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { Card } from '../Card';
import { Divider } from '../Divider';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
export const Menu = (_a) => {
    var { children } = _a, cardProps = __rest(_a, ["children"]);
    return (React.createElement(Card, Object.assign({}, cardProps, { variant: "overlay" }),
        React.createElement(ScrollContainer, { borderRadius: "card" }, children)));
};
const ScrollContainer = styled(Stack) `
  overflow-y: auto;
  max-height: 80vh;
  @media (pointer: fine) {
    overscroll-behavior: none;
  }
`;
export const MenuItemGroup = ({ children }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuDivider, { borderColor: "borderSecondary" }),
        children));
};
const MenuDivider = styled(Divider) `
  &:first-child {
    display: none;
  }
`;
export const MenuItem = (_a) => {
    var { label, sublabel, icon: IconComp, iconRight: IconRightComp } = _a, buttonProps = __rest(_a, ["label", "sublabel", "icon", "iconRight"]);
    return (React.createElement(MenuItemButton, Object.assign({}, buttonProps),
        React.createElement(Shelf, { gap: 1, px: 2, py: 1, minHeight: "48px" },
            IconComp && isComponent(IconComp) ? React.createElement(IconComp, { size: "iconMedium" }) : IconComp,
            React.createElement(Stack, { alignItems: "flex-start" },
                React.createElement(Text, { variant: "interactive1", color: "inherit" }, label),
                React.createElement(Sublabel, { variant: "label3", color: "inherit" }, sublabel)),
            React.createElement(Box, { ml: "auto", display: "flex" }, IconRightComp && (isComponent(IconRightComp) ? React.createElement(IconRightComp, { size: "iconSmall" }) : IconRightComp)))));
};
const Sublabel = styled(Text) ``;
const MenuItemButton = styled.button `
  cursor: pointer;
  padding: 0;
  border: none;
  appearance: none;
  background: transparent;
  outline: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.textPrimary};

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.accentPrimary};
    color: ${({ theme }) => theme.colors.textInverted};
    * {
      color: ${({ theme }) => theme.colors.textInverted};
    }
  }
`;
function isComponent(object) {
    return typeof object === 'function';
}
