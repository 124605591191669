import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconDownload(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.293 9.293L13 12.586V2h-2v10.586L7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707-1.414-1.414zM22 20v-4h-2v4H4v-4H2v4a2 2 0 002 2h16a2 2 0 002-2z", fill: "currentcolor" }),
        React.createElement("path", { d: "M13 12.586h-.2v.483l.341-.342-.141-.141zm3.293-3.293l.141-.142-.141-.141-.142.141.142.142zM13 2h.2v-.2H13V2zm-2 0v-.2h-.2V2h.2zm0 10.586l-.141.141.341.342v-.483H11zM7.707 9.293l.142-.142-.142-.141-.141.141.141.142zm-1.414 1.414l-.142-.141-.141.141.141.141.142-.14zM12 16.414l-.141.142.141.141.141-.141-.141-.142zm5.707-5.707l.142.141.14-.14-.14-.142-.142.141zM22 16h.2v-.2H22v.2zm-2 0v-.2h-.2v.2h.2zm0 4v.2h.2V20H20zM4 20h-.2v.2H4V20zm0-4h.2v-.2H4v.2zm-2 0v-.2h-.2v.2H2zm11.141-3.273l3.293-3.293-.283-.283-3.292 3.293.282.283zM12.8 2v10.586h.4V2h-.4zm-1.8.2h2v-.4h-2v.4zm.2 10.386V2h-.4v10.586h.4zM7.566 9.434l3.293 3.293.282-.283L7.85 9.151l-.283.283zM6.434 10.85L7.85 9.433l-.283-.283-1.415 1.415.283.282zm5.707 5.424l-5.707-5.707-.283.282 5.708 5.708.282-.283zm5.425-5.707l-5.707 5.707.282.283 5.708-5.708-.283-.282zM16.15 9.434l1.415 1.415.283-.283-1.415-1.415-.283.283zM21.8 16v4h.4v-4h-.4zm-1.8.2h2v-.4h-2v.4zm.2 3.8v-4h-.4v4h.4zM4 20.2h16v-.4H4v.4zM3.8 16v4h.4v-4h-.4zm-1.8.2h2v-.4H2v.4zm.2 3.8v-4h-.4v4h.4zM4 21.8A1.8 1.8 0 012.2 20h-.4A2.2 2.2 0 004 22.2v-.4zm16 0H4v.4h16v-.4zm1.8-1.8a1.8 1.8 0 01-1.8 1.8v.4a2.2 2.2 0 002.2-2.2h-.4z", fill: "#fff" })));
}
SvgIconDownload.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconDownload;
