function isObject(value) {
    return value != null && !Array.isArray(value) && typeof value === 'object';
}
export function mapResponsive(prop, mapper) {
    if (Array.isArray(prop)) {
        return prop.map((item) => {
            if (item === null) {
                return null;
            }
            return mapper(item);
        });
    }
    if (isObject(prop)) {
        return Object.entries(prop).reduce((result, [key, value]) => {
            result[key] = mapper(value);
            return result;
        }, {});
    }
    if (prop != null) {
        return mapper(prop);
    }
    return null;
}
