import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import { background, border, color, compose, flexbox, get, grid, layout, position, space, system, textAlign, } from 'styled-system';
const bleed = system({
    bleedX: {
        properties: ['marginLeft', 'marginRight'],
        scale: 'space',
        transform: (n, scale) => `-${toPx(get(scale, n, n))}`,
    },
    bleedY: {
        properties: ['marginTop', 'marginBottom'],
        scale: 'space',
        transform: (n, scale) => `-${toPx(get(scale, n, n))}`,
    },
});
const aspect = system({
    aspectRatio: {
        property: 'aspectRatio',
    },
});
export const Box = styled('div').withConfig({
    shouldForwardProp: (prop) => shouldForwardProp(prop),
})(compose(space, layout, background, color, flexbox, grid, border, textAlign, position, bleed, aspect));
function toPx(n) {
    return typeof n === 'number' ? `${n}px` : n;
}
