export const fetchLambda = async (url: string, reqInit?: RequestInit) => {
  console.log('🚀 ~ url', url)
  const res = await fetch(`${import.meta.env.REACT_APP_CF_FUNCTIONS_URL}/${url}`, reqInit)
  if (!res.ok) {
    const resText = await res.text()
    throw new Error(`Error: ${resText}`)
  }
  const json = await res.json()
  return json
}
