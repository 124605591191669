import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconEye(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M12 15a3 3 0 100-6 3 3 0 000 6z", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconEye.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconEye;
