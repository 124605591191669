import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconUpload(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 20.414v-4h2v4h16v-4h2v4a2 2 0 01-2 2H4a2 2 0 01-2-2zM7.707 9.121L11 5.828v10.586h2V5.828l3.293 3.293 1.414-1.414L12 2 6.293 7.707l1.414 1.414z", fill: "currentcolor" })));
}
SvgIconUpload.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconUpload;
