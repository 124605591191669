import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
import { useListBox, useOption } from '@react-aria/listbox';
import { DismissButton, useOverlay } from '@react-aria/overlays';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { Item } from '@react-stately/collections';
import { useSelectState } from '@react-stately/select';
import React from 'react';
import styled from 'styled-components';
import { IconChevronDown, IconChevronUp } from '../..';
import { Box } from '../Box';
import { InputBox } from '../InputBox';
import { Stack } from '../Stack';
import { Text } from '../Text';
export const Select = ({ options, onSelect, onBlur, label, placeholder, value, disabled, errorMessage, }) => {
    const items = options.map((opt) => {
        return (React.createElement(Item, { key: opt.value, textValue: opt.value }, opt.label));
    });
    return (React.createElement(SelectInputInt, { items: options, label: label, placeholder: placeholder, selectedKey: value, onSelectionChange: onSelect, isDisabled: disabled, errorMessage: errorMessage, onBlur: onBlur }, items));
};
const SelectInputInt = (props) => {
    var _a;
    const state = useSelectState(props);
    const ref = React.useRef(null);
    const { triggerProps, valueProps, menuProps } = useSelect(Object.assign(Object.assign({}, props), { 'aria-label': !props.label ? props.placeholder : undefined }), state, ref);
    const { buttonProps } = useButton(triggerProps, ref);
    const IconComp = state.isOpen ? IconChevronUp : IconChevronDown;
    return (React.createElement(Stack, { gap: 1, width: "100%" },
        React.createElement(Stack, { position: "relative", width: "100%" },
            React.createElement(StyledTrigger, Object.assign({}, buttonProps, { ref: ref }),
                React.createElement(InputBox, { label: props.label, as: "div", disabled: props.isDisabled, active: (state.isOpen || state.isFocused) && !props.isDisabled, inputElement: React.createElement(Text, Object.assign({ color: !state.selectedItem || props.isDisabled
                            ? 'textDisabled'
                            : state.isFocused
                                ? 'accentPrimary'
                                : 'textPrimary' }, valueProps), state.selectedItem ? state.selectedItem.rendered : props.placeholder), rightElement: React.createElement(IconComp, { color: props.isDisabled ? 'textSecondary' : 'textPrimary', style: { margin: '-8px 0' } }) })),
            React.createElement(HiddenSelect, { state: state, triggerRef: ref, label: props.label, name: props.name, "aria-label": (_a = props.label) !== null && _a !== void 0 ? _a : props.placeholder }),
            state.isOpen && (React.createElement(Popover, { isOpen: state.isOpen, onClose: state.close },
                React.createElement(ListBox, Object.assign({}, menuProps, { state: state }))))),
        props.errorMessage && (React.createElement(Box, { px: 2 },
            React.createElement(Text, { variant: "label2", color: "statusCritical" }, props.errorMessage)))));
};
const ListBox = (props) => {
    const ref = React.useRef(null);
    const { state } = props;
    const { listBoxProps } = useListBox(props, state, ref);
    return (React.createElement(StyledListBox, Object.assign({}, listBoxProps, { ref: ref }), [...state.collection].map((item) => (React.createElement(Option, { key: item.key, item: item, state: state })))));
};
const Option = ({ item, state }) => {
    const ref = React.useRef(null);
    const { optionProps, isSelected, isFocused, isDisabled } = useOption({ key: item.key }, state, ref);
    const statusProps = { isSelected, isFocused, isDisabled };
    return (React.createElement(StyledOption, Object.assign({}, optionProps, statusProps, { ref: ref }),
        React.createElement(StyledOptionText, Object.assign({ variant: "body1" }, statusProps), item.rendered)));
};
const Popover = (props) => {
    const ref = React.useRef(null);
    const { isOpen, onClose, children } = props;
    const { overlayProps } = useOverlay({
        isOpen,
        onClose,
        shouldCloseOnBlur: true,
        isDismissable: true,
    }, ref);
    // Add a hidden <DismissButton> component at the end of the popover
    // to allow screen reader users to dismiss the popup easily.
    return (React.createElement(FocusScope, { restoreFocus: true },
        React.createElement(StyledPopover, Object.assign({}, overlayProps, { ref: ref }),
            children,
            React.createElement(DismissButton, { onDismiss: onClose }))));
};
// Styles
const StyledTrigger = styled.button `
  display: flex;
  width: 100%;
  padding: 0;
  appearance: none;
  background: transparent;
  border: none;
  text-align: left;
`;
const StyledPopover = styled.div `
  position: absolute;
  top: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundInput};
  border-radius: ${({ theme }) => theme.radii.input}px;
  overflow: hidden;
  z-index: 20;
  box-shadow: ${({ theme }) => theme.shadows.cardOverlay};
`;
// TODO Configure maxHeight and overflow via props
const StyledListBox = styled.ul `
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 162px;
  overflow: auto;
`;
const StyledOption = styled.li `
  box-sizing: border-box;
  background: ${({ theme, isFocused, isDisabled }) => {
    if (isFocused)
        return theme.colors.primarySelectedBackground;
    if (isDisabled)
        return theme.colors.backgroundSecondary;
    return theme.colors.backgroundInput;
}};
  cursor: pointer;
  outline: none;
  padding: 16px;
`;
const StyledOptionText = styled(Text) `
  color: ${({ theme, isDisabled, isFocused }) => {
    if (isFocused)
        return theme.colors.textInverted;
    if (isDisabled)
        return theme.colors.textDisabled;
    return theme.colors.textPrimary;
}} !important;
`;
