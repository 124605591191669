import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconCheckInCircle(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("circle", { cx: 12, cy: 12.783, r: 10, stroke: "currentcolor", strokeWidth: 1.5 }),
        React.createElement("path", { d: "M17.25 9.283l-7.219 7L6.75 13.1", stroke: "currentcolor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconCheckInCircle.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconCheckInCircle;
