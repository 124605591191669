import css from '@styled-system/css';
import styled from 'styled-components';
import { Flex } from '../Flex';
export const Shelf = styled(Flex)((props) => css({
    columnGap: props.gap,
    rowGap: props.rowGap != null ? props.rowGap : props.gap,
}));
Shelf.defaultProps = {
    alignItems: 'center',
};
