var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { IconFileText } from '../..';
import IconAlertCircle from '../../icon/IconAlertCircle';
import IconSpinner from '../../icon/IconSpinner';
import IconUpload from '../../icon/IconUpload';
import IconX from '../../icon/IconX';
import useControlledState from '../../utils/useControlledState';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
const rotate = keyframes `
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
`;
const FileUploadContainer = styled(Stack) `
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme, $disabled }) => ($disabled ? theme.colors.backgroundPage : theme.colors.backgroundInput)};
  /* outline: 1px dashed
    ${({ theme, $disabled }) => ($disabled ? theme.colors.backgroundSecondary : theme.colors.borderPrimary)};
  outline-offset: -1px; */
  border-radius: ${({ theme }) => theme.radii.card}px;
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'initial')};
`;
const AddButton = styled(Shelf) `
  transition: color 100ms ease-in-out;
`;
const UploadButton = styled.button `
  // Absolutely positioned, to avoid nesting the clear button in this one
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme, disabled }) => disabled ? `1px solid ${theme.colors.borderSecondary}` : `1px dashed ${theme.colors.borderPrimary}`};
  border-radius: ${({ theme }) => theme.radii.card}px;
  background: transparent;
  appearance: none;
  transition: border-color 100ms ease-in-out, color 100ms ease-in-out;
  cursor: pointer;

  &:disabled,
  &:hover {
    & + ${AddButton} {
      color: ${({ theme }) => theme.colors.textDisabled};
    }
  }

  &:focus-visible,
  &:hover {
    color: ${({ theme }) => theme.colors.accentPrimary};
    border-color: currentcolor;

    & + ${AddButton} {
      color: ${({ theme }) => theme.colors.accentPrimary};
    }
  }

  ${({ $active, theme }) => $active &&
    css `
      color: ${theme.colors.accentPrimary};
      border-color: currentcolor;
      & + ${AddButton} {
        color: ${theme.colors.accentPrimary};
      }
    `}
`;
UploadButton.defaultProps = {
    type: 'button',
};
const FormField = styled.input `
  // Visually hidden
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
const Spinner = styled(IconSpinner) `
  animation: ${rotate} 600ms linear infinite;
`;
export const FileUpload = ({ file: fileProp, onFileChange, onClear, validate, errorMessage: errorMessageProp, accept, disabled, loading, placeholder, label, }) => {
    const inputRef = useRef(null);
    const [curFile, setCurFile] = useControlledState(null, fileProp, onFileChange);
    const [error, setError] = useState(null);
    const [dragOver, setDragOver] = useState(false);
    const errorMessage = errorMessageProp || error;
    function handleUploadBtnClick() {
        var _a;
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    }
    function handleClear() {
        if (onClear) {
            onClear();
        }
        setError(null);
        setCurFile(null);
    }
    function handleNewFile(newFile) {
        return __awaiter(this, void 0, void 0, function* () {
            setError(null);
            if (curFile) {
                handleClear();
            }
            const newError = validate === null || validate === void 0 ? void 0 : validate(newFile);
            if (newError) {
                setError(newError);
                return;
            }
            setCurFile(newFile);
        });
    }
    function handleFileChange(e) {
        const { files: newFiles } = e.target;
        if (newFiles === null || newFiles === void 0 ? void 0 : newFiles.length) {
            const newFile = newFiles[0];
            handleNewFile(newFile);
        }
    }
    function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
        const { files: newFiles } = e.dataTransfer;
        if (newFiles === null || newFiles === void 0 ? void 0 : newFiles.length) {
            const newFile = newFiles[0];
            handleNewFile(newFile);
        }
    }
    function handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    }
    function handleDragEnd() {
        setDragOver(false);
    }
    return (React.createElement(Stack, { gap: 1, width: "100%" },
        React.createElement(FileUploadContainer, { "$disabled": disabled, px: 2, py: 1, onDragOver: handleDrag, onDragEnter: handleDrag, onDragEnd: handleDragEnd, onDragLeave: handleDragEnd, onDrop: handleDrop },
            React.createElement(FormField, { type: "file", accept: accept, onChange: handleFileChange, value: "", disabled: disabled, tabIndex: -1, ref: inputRef }),
            React.createElement(Stack, { gap: "4px", height: "100%" },
                label && (React.createElement(Text, { variant: "label2", color: disabled ? 'textDisabled' : 'textSecondary' }, label)),
                curFile ? (React.createElement(React.Fragment, null,
                    React.createElement(Shelf, { gap: 1, my: "auto" },
                        React.createElement(UploadButton, { onClick: handleUploadBtnClick, disabled: disabled, "$active": dragOver }),
                        React.createElement(Flex, { minWidth: "iconMedium" }, loading ? (React.createElement(Spinner, { color: disabled ? 'textDisabled' : 'textPrimary' })) : errorMessage ? (React.createElement(IconAlertCircle, { color: disabled ? 'textDisabled' : 'textPrimary' })) : (React.createElement(IconFileText, { color: disabled ? 'textDisabled' : 'textPrimary' }))),
                        React.createElement(Text, { variant: "body1", color: disabled ? 'textDisabled' : 'textPrimary', style: {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            } }, typeof curFile === 'string' ? curFile : curFile.name),
                        React.createElement(Box, { display: "flex", position: "relative", zIndex: "1", ml: "auto", my: "-10px", mr: "-10px", minWidth: "iconMedium" }, !disabled && React.createElement(Button, { variant: "tertiary", onClick: handleClear, icon: IconX, disabled: disabled }))))) : (React.createElement(React.Fragment, null,
                    React.createElement(UploadButton, { onClick: handleUploadBtnClick, disabled: disabled, "$active": dragOver }),
                    React.createElement(AddButton, { gap: 1, justifyContent: "center", m: "auto" },
                        React.createElement(IconUpload, null),
                        React.createElement(Text, { variant: "body1", color: "currentcolor" }, placeholder)))))),
        errorMessage && (React.createElement(Box, { px: 2 },
            React.createElement(Text, { variant: "label2", color: "statusCritical" }, errorMessage)))));
};
