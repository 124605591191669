import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconArrowDown(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M8.667 3.833v9.334M4 8.5l4.667 4.667L13.333 8.5", stroke: "currentcolor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconArrowDown.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconArrowDown;
