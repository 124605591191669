var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import styled, { css, useTheme } from 'styled-components';
import IconNftThumbnail from '../../icon/IconNftThumbnail';
import { Stack } from '../Stack';
import { Text } from '../Text';
export const Thumbnail = (_a) => {
    var { label } = _a, props = __rest(_a, ["label"]);
    const theme = useTheme();
    return (React.createElement(React.Fragment, null, props.type === 'nft' ? (React.createElement(IconNftThumbnail, { color: "backgroundThumbnail", size: props.size === 'large' ? 'iconLarge' : 'iconMedium', 
        // @ts-expect-error
        style: { '--secondary': theme.colors.textInverted } })) : (React.createElement(StyledThumbnail, Object.assign({ fontWeight: 500 }, props),
        React.createElement(Stack, { position: "relative", zIndex: "1" },
            React.createElement("span", null, label.slice(0, 3)),
            React.createElement("span", null, label.slice(3, 6)))))));
};
const StyledThumbnail = styled(Text) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  ${({ type, theme }) => {
    switch (type) {
        case 'pool':
            return css({
                borderRadius: '4px',
                background: theme.colors.backgroundThumbnail,
                color: theme.colors.textInverted,
            });
        case 'asset':
            return css({
                color: theme.colors.textInverted,
                background: 'transparent',
                '&::before': {
                    content: '""',
                    width: '80%',
                    height: '80%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    margin: 'auto',
                    zIndex: 0,
                    transform: 'rotate(45deg)',
                    background: theme.colors.backgroundThumbnail,
                    color: theme.colors.textInverted,
                    borderRadius: '4px',
                },
            });
        case 'token':
        default:
            return css({
                borderRadius: '100%',
                background: theme.colors.backgroundThumbnail,
                color: theme.colors.textInverted,
            });
    }
}};
  ${({ size, theme }) => {
    switch (size) {
        case 'large':
            return css({
                lineHeight: '0.9',
                fontSize: '14px',
                height: theme.sizes.iconLarge,
                width: theme.sizes.iconLarge,
                minWidth: theme.sizes.iconLarge,
            });
        case 'small':
        default:
            return css({
                lineHeight: '1',
                fontSize: '8px',
                height: theme.sizes.iconMedium,
                width: theme.sizes.iconMedium,
                minWidth: theme.sizes.iconMedium,
            });
    }
}}
`;
