import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import IconAlertCircle from '../../icon/IconAlertCircle';
import IconCheckCircle from '../../icon/IconCheckCircle';
import IconInfo from '../../icon/IconInfo';
import IconInfoFailed from '../../icon/IconInfoFailed';
import IconSpinner from '../../icon/IconSpinner';
import IconX from '../../icon/IconX';
import { Box } from '../Box';
import { Button } from '../Button';
import { Card } from '../Card';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
const rotate = keyframes `
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(1turn);
	}
`;
const StyledSpinner = styled(IconSpinner) `
  animation: ${rotate} 0.8s linear infinite;
`;
const statusIcons = {
    info: IconInfo,
    pending: StyledSpinner,
    ok: IconCheckCircle,
    warning: IconAlertCircle,
    critical: IconInfoFailed,
};
const statusColors = {
    info: 'statusInfo',
    pending: 'statusInfo',
    ok: 'statusOk',
    warning: 'statusWarning',
    critical: 'statusCritical',
};
export const Toast = ({ status = 'info', label, sublabel, onDismiss, onStatusChange, action, }) => {
    const Icon = statusIcons[status];
    React.useEffect(() => {
        onStatusChange && onStatusChange(status);
    }, [status, onStatusChange]);
    return (React.createElement(Card, { variant: "overlay" },
        React.createElement(Shelf, { gap: 2, px: 2, py: 1 },
            React.createElement(Box, { minWidth: "iconMedium", display: "flex" },
                React.createElement(Icon, { size: "iconMedium", color: statusColors[status] })),
            React.createElement(Stack, { alignItems: "flex-start" },
                React.createElement(Text, { variant: "heading4" }, label),
                React.createElement(Text, { variant: "label2" }, sublabel)),
            React.createElement(Shelf, { ml: "auto" },
                action,
                onDismiss && React.createElement(Button, { variant: "tertiary", icon: IconX, onClick: onDismiss })))));
};
