import { FocusScope } from '@react-aria/focus';
import { useOverlay, useOverlayTrigger } from '@react-aria/overlays';
import { useOverlayTriggerState } from '@react-stately/overlays';
import * as React from 'react';
import { Positioner } from '../Positioner';
export const Popover = ({ renderTrigger, renderContent }) => {
    const state = useOverlayTriggerState({});
    const overlayRef = React.useRef(null);
    const triggerRef = React.useRef(null);
    // Get props for the trigger and overlay. This also handles
    // hiding the overlay when a parent element of the trigger scrolls
    // (which invalidates the popover positioning).
    const { triggerProps: triggerAriaProps, overlayProps: overlayAriaProps } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);
    const { overlayProps: overlayBehaviorProps } = useOverlay({
        onClose: state.close,
        isOpen: state.isOpen,
        isDismissable: true,
    }, overlayRef);
    return (React.createElement(React.Fragment, null,
        renderTrigger(Object.assign(Object.assign({}, triggerAriaProps), { onClick: () => state.open() }), triggerRef, state),
        state.isOpen && (React.createElement(Positioner, { isShown: true, targetRef: triggerRef, overlayRef: overlayRef, render: (positionProps) => (React.createElement(FocusScope, { contain: true, restoreFocus: true, autoFocus: true }, renderContent(Object.assign(Object.assign(Object.assign({}, positionProps), overlayAriaProps), overlayBehaviorProps), overlayRef, state))) }))));
};
