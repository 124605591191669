const typography = {
    heading1: {
        fontSize: [20, 24],
        lineHeight: 1.25,
        fontWeight: [500, 700],
        color: 'textPrimary',
    },
    heading2: {
        fontSize: 18,
        lineHeight: 1.25,
        fontWeight: 600,
        color: 'textPrimary',
    },
    heading3: {
        fontSize: 16,
        lineHeight: 1.375,
        fontWeight: [500, 600],
        color: 'textPrimary',
    },
    heading4: {
        fontSize: 14,
        lineHeight: 1.375,
        fontWeight: 600,
        color: 'textPrimary',
    },
    heading5: {
        fontSize: 14,
        lineHeight: 1.375,
        fontWeight: 600,
        color: 'textSecondary',
    },
    heading6: {
        fontSize: 14,
        lineHeight: 1.375,
        fontWeight: 500,
        color: 'textSecondary',
    },
    emphasized: {
        fontWeight: 600,
    },
    interactive1: {
        fontSize: 14,
        lineHeight: 1.375,
        fontWeight: 500,
        color: 'textPrimary',
    },
    interactive2: {
        fontSize: 12,
        lineHeight: 1.375,
        fontWeight: 500,
        color: 'textPrimary',
    },
    body1: {
        fontSize: 16,
        lineHeight: 1.405,
        fontWeight: 400,
        color: 'textPrimary',
    },
    body2: {
        fontSize: 14,
        lineHeight: 1.4,
        fontWeight: 400,
        color: 'textPrimary',
    },
    body3: {
        fontSize: 12,
        lineHeight: 1.25,
        fontWeight: 400,
        color: 'textPrimary',
        letterSpacing: 0.01,
    },
    body4: {
        fontSize: 10,
        lineHeight: 1.25,
        fontWeight: 400,
        color: 'textSecondary',
    },
    label1: {
        fontSize: 14,
        lineHeight: 1.375,
        fontWeight: 500,
        color: 'textSecondary',
    },
    label2: {
        fontSize: 12,
        lineHeight: 1.375,
        fontWeight: 400,
        color: 'textSecondary',
    },
    label3: {
        fontSize: 10,
        lineHeight: 1.25,
        fontWeight: 500,
        color: 'textPrimary',
    },
};
export { typography };
