var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { mapResponsive } from '../../utils/styled';
import { Box } from '../Box';
export const Grid = (_a) => {
    var { gap, rowGap = gap, equalRows, equalColumns = false, minColumnWidth, columns = 2 } = _a, rest = __rest(_a, ["gap", "rowGap", "equalRows", "equalColumns", "minColumnWidth", "columns"]);
    const templateColumns = minColumnWidth
        ? widthToColumns(minColumnWidth, equalColumns)
        : countToColumns(columns, equalColumns);
    return (React.createElement(Box, Object.assign({ display: "grid", gridColumnGap: gap, gridRowGap: rowGap, gridTemplateColumns: templateColumns, gridAutoRows: equalRows ? '1fr' : undefined }, rest)));
};
function toPx(n) {
    return typeof n === 'number' ? `${n}px` : n;
}
function widthToColumns(width, equalColumns) {
    return mapResponsive(width, (value) => `repeat(auto-fit, minmax(${toPx(value)}, ${equalColumns ? '1fr' : 'auto'}))`);
}
function countToColumns(count, equalColumns) {
    return mapResponsive(count, (value) => `repeat(${value}, ${equalColumns ? '1fr' : 'auto'})`);
}
