import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconChevronRight(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 27", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M9 20.22l6-6.74-6-6.74", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconChevronRight.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconChevronRight;
