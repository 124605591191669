export const grayScale = {
    50: '#fafafa',
    80: '#f8f8f8',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    950: '#171717',
};
export const blueScale = {
    30: '#fafbff',
    50: '#f0f4ff',
    100: '#dbe5ff',
    200: '#b3c8ff',
    300: '#7a9fff',
    400: '#4c7eff',
    500: '#1253ff',
    600: '#003cdb',
    700: '#002b9e',
    800: '#001c66',
};
export const yellowScale = {
    500: '#ffc012',
    700: '#574000',
};
export const centrifugeBlue = blueScale[500];
export const altairYellow = yellowScale[500];
