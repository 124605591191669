import * as React from 'react'

export const LogoAltair: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> = (props) => {
  return (
    <svg viewBox="0 0 247 244" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M205.009 75.129c-.831 2.194-35.706-19.833-67.555-4.805-23.156 10.926-32.722 32.109-32.722 50.171 0 14.232 4.916 24.984 10.29 31.991 8.741 11.396 16.873 15.684 23.895 19.493 11.312 6.137 30.55 6.342 36.391 6.037 10.876-.568 23.654-3.729 34.429-8.785 4.113-1.93 10.794-6.117 16.646-10.8 9.992-7.997 19.196-18.057 19.035-17.278-.254 1.234-.151 2.21-5.148 11.025-14.806 26.116-39.42 37.509-39.42 37.509-34.676 18.44-65.449 8.876-71.43 6.58-26.257-10.078-39.951-28.974-43.15-34.983-5.218-9.8-14.573-45.796-2.04-74.407 9.29-21.203 30.823-31.62 33.2-32.617 38.367-16.097 88.333 18.875 87.579 20.869z"
        fill="url(#altair_svg__paint0_linear_3141:523)"
      />
      <path
        d="M206.105 73.302c-1.559 1.452-12.759-12.06-30.741-21.818-5.142-2.789-24.755-12.813-48.711-12.299-39.712.85-66.103 30.13-70.809 35.557-23.522 27.122-34.596 68.885-21.366 101.385 17.194 42.232 74.723 66.522 70.349 67.001l-5.034-1.617C93.722 239.104 19.2 205.37 16.53 138.149c-1.505-37.876 10.612-68.552 17.948-76.925C47.296 46.593 57.574 39.549 66.24 34.897c17.41-9.342 50.155-17.347 74.456-13.67 27.267 4.127 44.383 23.26 52.729 32.59 7.529 8.414 13.94 18.311 12.681 19.485z"
        fill="url(#altair_svg__paint1_linear_3141:523)"
      />
      <path
        d="M205.907 75.092c1.842-.822-.616-25.974-16.88-45.101-6.559-7.73-13.771-14.757-22.625-19.676-36.604-19.93-81.611-3.903-91.354 0-9.98 3.997-31.273 14.918-49.15 37.324C5.747 72.9 2.763 100 1.615 111.862c-4.902 50.653 21.315 90.432 23.181 89.833 1.088-.349-10.806-25.66-8.266-63.545.968-14.422 2.955-40.839 21.16-66.194 11.029-14.954 23.787-27.401 40.43-35.62 9.63-4.779 44.09-20.184 78.598-7.95a86.642 86.642 0 0128.507 17.1c15.023 13.793 19.175 30.28 20.682 29.606z"
        fill="url(#altair_svg__paint2_linear_3141:523)"
      />
      <path
        d="M152.889 216.972c-.864 1.478-60.568-15.638-82.997-57.821-3.375-6.345-18.964-40.171-8.737-66.505 4.278-11.015 12.698-23.346 16.1-27.176 5.494-6.186 14.814-11.748 26.649-16.787 5.318-2.211 15.033-4.513 20.74-5.275 5.657-.7 14.347-.55 20.02 0 11.031 1.068 25.937 14.006 25.518 14.006-.113 0-11.832-3.774-23.948-4.61-10.043-.693-20.938 1.65-24.801 3.022-8.526 3.027-20.21 9.644-26.967 18.003-6.756 8.36-12.523 20.175-13.751 36.013-.9 11.61.335 28.546 5.345 41.75 2.401 6.329 6.537 14.383 14.487 24.99 16.921 22.578 52.967 39.332 52.342 40.39z"
        fill="url(#altair_svg__paint3_linear_3141:523)"
      />
      <path
        d="M120.541 38.114c10.358-1.5 19.222-2.017 29.492 0 11.786 2.378 18.304 4.829 26.332 8.953 13.71 7.044 31.062 25.105 29.74 27.816-2.354 4.832-38.888-33.996-89.079-20.633-14.195 4.866-22.949 9.41-32.013 16.352-15.44 11.822-23.09 27.373-25.508 33.732-4.15 10.918-5.278 17.062-5.78 28.68 0 15.644 2.914 24.719 6.44 34.666 5.014 14.157 14.366 30.341 12.863 29.292-17.181-11.989-27.917-48.983-29.104-56.65-1.186-7.667-6.251-40.878 15.58-68.532 19.904-25.209 53.317-32.547 61.037-33.676z"
        fill="url(#altair_svg__paint4_linear_3141:523)"
      />
      <defs>
        <linearGradient
          id="altair_svg__paint0_linear_3141:523"
          x1={183.161}
          y1={50.852}
          x2={183.161}
          y2={201.427}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAB961" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="altair_svg__paint1_linear_3141:523"
          x1={111.333}
          y1={20.314}
          x2={111.333}
          y2={243.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAB961" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="altair_svg__paint2_linear_3141:523"
          x1={103.751}
          y1={1.775}
          x2={103.751}
          y2={201.706}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={0.414} stopColor="#FAB961" />
        </linearGradient>
        <linearGradient
          id="altair_svg__paint3_linear_3141:523"
          x1={106.145}
          y1={50.273}
          x2={106.145}
          y2={212.856}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#FAB961" />
        </linearGradient>
        <linearGradient
          id="altair_svg__paint4_linear_3141:523"
          x1={124.372}
          y1={40.703}
          x2={124.372}
          y2={197.02}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#FAB961" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const LogoAltairText: React.FC<Omit<React.SVGProps<SVGSVGElement>, 'ref'>> = (props) => {
  return (
    <svg width="124" height="80" viewBox="0 0 124 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.0103 33.2677C54.8194 33.7724 46.7988 28.7064 39.4741 32.1626C34.1486 34.6755 31.9485 39.547 31.9485 43.7011C31.9485 46.9741 33.0792 49.447 34.3151 51.0584C36.3254 53.6794 38.1955 54.6654 39.8106 55.5415C42.412 56.9528 46.8364 56.9999 48.1797 56.9298C50.681 56.7993 53.6197 56.0723 56.0977 54.9094C57.0437 54.4655 58.5802 53.5027 59.926 52.4256C62.224 50.5865 64.3407 48.273 64.3038 48.452C64.2453 48.7359 64.269 48.9604 63.1198 50.9876C59.7148 56.9938 54.054 59.6139 54.054 59.6139C46.0791 63.8549 39.0019 61.6552 37.6263 61.1272C31.5878 58.8094 28.4384 54.4638 27.7025 53.0818C26.5025 50.8279 24.3512 42.5496 27.2337 35.9695C29.3698 31.0933 34.3222 28.6975 34.869 28.4682C43.6926 24.7662 55.1838 32.8091 55.0103 33.2677Z"
        fill="url(#paint0_linear_7639_132256)"
      />
      <path
        d="M55.2625 32.848C54.9039 33.1819 52.3281 30.0745 48.1926 27.8304C47.0102 27.1889 42.4994 24.8834 36.9901 25.0018C27.857 25.1971 21.7876 31.9312 20.7053 33.1792C15.2958 39.4168 12.7489 49.0215 15.7915 56.4958C19.7458 66.2083 32.9764 71.7947 31.9704 71.9049L30.8129 71.5329C29.4164 70.9793 12.2777 63.2212 11.6638 47.7617C11.3177 39.0507 14.1044 31.996 15.7915 30.0702C18.7395 26.7053 21.1033 25.0854 23.096 24.0154C27.1001 21.867 34.6306 20.026 40.2194 20.8717C46.4903 21.8209 50.4266 26.2209 52.3461 28.3667C54.0777 30.3018 55.552 32.578 55.2625 32.848Z"
        fill="url(#paint1_linear_7639_132256)"
      />
      <path
        d="M55.217 33.2595C55.6406 33.0704 55.0752 27.2859 51.3349 22.887C49.8265 21.1095 48.1679 19.4932 46.1316 18.3621C37.7133 13.7784 27.3626 17.4644 25.122 18.3621C22.8268 19.2812 17.9296 21.7928 13.8183 26.9459C9.18391 32.7553 8.49758 38.9878 8.23373 41.716C7.10622 53.3653 13.1358 62.5137 13.5649 62.3759C13.815 62.2955 11.0796 56.4746 11.6638 47.7617C11.8863 44.4449 12.3435 38.3696 16.5304 32.5383C19.0667 29.0992 22.0008 26.2365 25.8284 24.3465C28.0432 23.2473 35.9682 19.7044 43.9044 22.5181C46.3223 23.3929 48.5485 24.7283 50.4605 26.4506C53.9155 29.6229 54.8705 33.4146 55.217 33.2595Z"
        fill="url(#paint2_linear_7639_132256)"
      />
      <path
        d="M43.0238 65.8893C42.825 66.2292 29.0943 62.2929 23.9361 52.5917C23.1598 51.1323 19.5747 43.353 21.9268 37.2967C22.9104 34.7635 24.847 31.9275 25.6292 31.0466C26.8928 29.624 29.0363 28.345 31.7582 27.186C32.9812 26.6775 35.2155 26.1482 36.528 25.9729C37.8291 25.812 39.8274 25.8465 41.1323 25.9729C43.669 26.2185 47.0972 29.1941 47.0008 29.1941C46.9748 29.1941 44.2798 28.3261 41.4932 28.1337C39.1835 27.9743 36.678 28.5131 35.7894 28.8287C33.8288 29.525 31.1415 31.0466 29.5877 32.9691C28.0339 34.8916 26.7075 37.6089 26.425 41.2514C26.2179 43.9216 26.5021 47.8166 27.6543 50.8531C28.2066 52.3087 29.1578 54.161 30.9861 56.6005C34.8776 61.793 43.1676 65.646 43.0238 65.8893Z"
        fill="url(#paint3_linear_7639_132256)"
      />
      <path
        d="M35.5844 24.7551C37.9664 24.4102 40.0051 24.2912 42.3669 24.7551C45.0775 25.302 46.5766 25.8657 48.4227 26.8141C51.5757 28.4341 55.5664 32.5879 55.2624 33.2113C54.7211 34.3226 46.3188 25.3929 34.7758 28.466C31.5114 29.5853 29.4981 30.6304 27.4134 32.2268C23.8626 34.9457 22.1031 38.522 21.5471 39.9845C20.5926 42.4955 20.3333 43.9084 20.2178 46.5805C20.2178 50.1783 20.8881 52.2653 21.6987 54.5528C22.8519 57.8087 25.0027 61.5308 24.6572 61.2896C20.7059 58.5322 18.2367 50.0243 17.9639 48.261C17.6911 46.4977 16.5261 38.8599 21.5471 32.5001C26.1245 26.7024 33.8087 25.0149 35.5844 24.7551Z"
        fill="url(#paint4_linear_7639_132256)"
      />
      <path
        d="M47.0165 39.5355L46.808 39.9963C46.3253 39.5904 45.6999 39.36 44.9977 39.36C43.1874 39.36 41.8927 40.6656 41.8927 42.4979C41.8927 44.3411 43.1874 45.6577 44.9977 45.6577C45.6889 45.6577 46.3033 45.4383 46.7861 45.0433L46.9616 45.4602H48.5415V39.5355H47.0165ZM45.272 43.8584C44.504 43.8584 43.9444 43.2769 43.9444 42.4979C43.9444 41.7299 44.504 41.1593 45.272 41.1593C46.04 41.1593 46.5995 41.7299 46.5995 42.4979C46.5995 43.2769 46.04 43.8584 45.272 43.8584Z"
        fill="white"
      />
      <path d="M50.5741 45.4602H52.6148V37.396H50.5741V45.4602Z" fill="white" />
      <path
        d="M58.1415 43.6828C57.7136 43.6828 57.4503 43.4195 57.4503 43.0026V41.1154H58.8876V39.5355H57.4064V37.813H56.9785L54.3234 40.6327V41.1154H55.4096V43.3427C55.4096 44.6593 56.2105 45.4602 57.5161 45.4602H58.9205V43.6828H58.1415Z"
        fill="white"
      />
      <path
        d="M65.8954 39.5355L65.687 39.9963C65.2042 39.5904 64.5788 39.36 63.8766 39.36C62.0663 39.36 60.7717 40.6656 60.7717 42.4979C60.7717 44.3411 62.0663 45.6577 63.8766 45.6577C64.5679 45.6577 65.1823 45.4383 65.665 45.0433L65.8406 45.4602H67.4205V39.5355H65.8954ZM64.1509 43.8584C63.3829 43.8584 62.8234 43.2769 62.8234 42.4979C62.8234 41.7299 63.3829 41.1593 64.1509 41.1593C64.9189 41.1593 65.4785 41.7299 65.4785 42.4979C65.4785 43.2769 64.9189 43.8584 64.1509 43.8584Z"
        fill="white"
      />
      <path
        d="M69.464 38.8004H71.4828V37.0669H69.464V38.8004ZM69.453 45.4602H71.4938V39.5355H69.453V45.4602Z"
        fill="white"
      />
      <path
        d="M76.8326 39.5136C76.1304 39.5136 75.6257 39.7111 75.2088 40.1938L74.8906 39.5355H73.5192V45.4602H75.5599V42.454C75.5599 41.6969 75.8671 41.3788 76.5803 41.3788H77.3812V39.5136H76.8326Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7639_132256"
          x1="49.9859"
          y1="27.6844"
          x2="49.9859"
          y2="62.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAB961" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7639_132256"
          x1="33.4667"
          y1="20.6616"
          x2="33.4667"
          y2="71.9907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAB961" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7639_132256"
          x1="31.723"
          y1="16.3981"
          x2="31.723"
          y2="62.3784"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.414296" stopColor="#FAB961" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7639_132256"
          x1="32.2735"
          y1="27.5516"
          x2="32.2735"
          y2="64.9428"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#FAB961" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7639_132256"
          x1="36.4653"
          y1="25.3504"
          x2="36.4653"
          y2="61.3006"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#FAB961" />
        </linearGradient>
      </defs>
    </svg>
  )
}
