import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconInvestments(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#icon-investments_svg__clip0_2261_20068)", stroke: "currentcolor", strokeWidth: 1.5 },
            React.createElement("circle", { cx: 11.971, cy: 12, r: 3.396 }),
            React.createElement("path", { d: "M11.971 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M18.226 10.5a5.989 5.989 0 01-.888 5.094 6.425 6.425 0 01-4.867 2.698", strokeLinecap: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "icon-investments_svg__clip0_2261_20068" },
                React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" })))));
}
SvgIconInvestments.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconInvestments;
