var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { IconInfoFailed, IconSearch } from '../..';
import { Box } from '../Box';
import { InputBox } from '../InputBox';
import { Stack } from '../Stack';
import { Text } from '../Text';
const StyledTextInput = styled.input `
  width: 100%;
  border: 0;
  background: transparent;
  height: 22px;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.textSelected};
  }
`;
export const TextInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage, rightElement } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage", "rightElement"]);
    return (React.createElement(InputBox, { label: label, secondaryLabel: secondaryLabel, disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(StyledTextInput, Object.assign({ disabled: disabled }, inputProps)), rightElement: rightElement }));
};
const StyledClearButtom = styled.button `
  background: none;
  padding: 0;
  border: none;
  height: 24px;
  width: 24px;
`;
export const SearchInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage, clear } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage", "clear"]);
    const ref = useRef(null);
    return (React.createElement(InputBox, { ref: ref, label: label, secondaryLabel: secondaryLabel, disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(StyledTextInput, Object.assign({ type: "search", disabled: disabled }, inputProps)), rightElement: inputProps.value && clear ? (React.createElement(StyledClearButtom, { onClick: () => {
                var _a;
                clear();
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
            } },
            React.createElement(IconInfoFailed, { color: "textPrimary" }))) : (React.createElement(IconSearch, { size: "iconMedium", color: "textPrimary" })) }));
};
export const DateInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage"]);
    return (React.createElement(InputBox, { label: label, secondaryLabel: secondaryLabel, disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(StyledTextInput, Object.assign({ type: "date", disabled: disabled, required // hides the reset button in Firefox
            : true }, inputProps)) }));
};
const StyledNumberInput = styled(StyledTextInput) `
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const NumberInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage, rightElement } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage", "rightElement"]);
    return (React.createElement(InputBox, { label: label, secondaryLabel: secondaryLabel, disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(StyledNumberInput, Object.assign({ type: "number", disabled: disabled }, inputProps)), rightElement: rightElement }));
};
const StyledTextArea = styled(Box) `
  display: block;
  width: 100%;
  border: 2px solid transparent;
  background: transparent;
  min-height: 66px;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  resize: vertical;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.textSelected};
  }
`;
export const TextAreaInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage, rightElement } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage", "rightElement"]);
    const { space: [, one, two], } = useTheme();
    return (React.createElement(InputBox, { label: label, secondaryLabel: secondaryLabel, disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(Stack, { bleedX: 2, bleedY: 1 },
            React.createElement(Text, { variant: "body2" },
                React.createElement(StyledTextArea, Object.assign({ as: "textarea", px: `${two - 2}px`, py: `${one - 2}px`, disabled: disabled }, inputProps)))), rightElement: rightElement }));
};
