var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../Box';
import { InputBox } from '../InputBox';
import { Shelf } from '../Shelf';
import { Text } from '../Text';
const StyledTextInput = styled.input `
  width: 100%;
  border: 0;
  background: transparent;
  height: 36px;
  font-size: 24px;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  -moz-appearance: textfield;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.accentPrimary};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ $variant }) => $variant === 'small' &&
    css({
        fontSize: '16px',
        height: '20px',
    })}
`;
const StyledMaxButton = styled(Box) `
  padding: 0 8px;
  border: 0;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  cursor: pointer;
  appearance: none;

  &:disabled {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.buttonActive};
  }
`.withComponent('button');
StyledMaxButton.defaultProps = {
    type: 'button',
};
// regex from https://stackoverflow.com/questions/63091317/thousand-separator-input-with-react-hooks
function formatThousandSeparator(input) {
    const removeNonNumeric = (typeof input === 'string' ? input : input.toString()).replace(/[^0-9.]/g, ''); // remove non-numeric chars except .
    if (removeNonNumeric.includes('.')) {
        const decimalIndex = removeNonNumeric.indexOf('.');
        // add thousand separator only pre-decimal
        return `${removeNonNumeric.slice(0, decimalIndex).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${removeNonNumeric.slice(decimalIndex)}`;
    }
    return removeNonNumeric.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const CurrencyInput = (_a) => {
    var { label, secondaryLabel, disabled, errorMessage, currency, onSetMax, placeholder = '0.0', variant = 'large', initialValue, precision = 2 } = _a, inputProps = __rest(_a, ["label", "secondaryLabel", "disabled", "errorMessage", "currency", "onSetMax", "placeholder", "variant", "initialValue", "precision"]);
    const [value, setValue] = React.useState(initialValue ? formatThousandSeparator(initialValue) : '');
    const onChange = (value) => {
        const inputFormatted = formatThousandSeparator(value);
        const inputAsNumber = parseFloat(value.replaceAll(',', ''));
        if (inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange) {
            inputProps === null || inputProps === void 0 ? void 0 : inputProps.onChange(inputAsNumber);
            setValue(inputFormatted);
        }
    };
    React.useEffect(() => {
        if (inputProps.value) {
            const inputFormatted = formatThousandSeparator(Math.floor(inputProps.value * Math.pow(10, precision)) / Math.pow(10, precision));
            setValue(inputFormatted);
        }
    }, [inputProps.value]);
    return (React.createElement(InputBox, { label: label, secondaryLabel: React.createElement(Shelf, { justifyContent: "space-between" },
            React.createElement("span", null, secondaryLabel),
            onSetMax && (React.createElement(StyledMaxButton, { onClick: onSetMax, disabled: disabled },
                React.createElement(Text, { variant: "label3", lineHeight: 1.5, color: "inherit" }, "MAX")))), disabled: disabled, errorMessage: errorMessage, inputElement: React.createElement(StyledTextInput, Object.assign({}, inputProps, { disabled: disabled, placeholder: placeholder, type: "text", onChange: (e) => onChange(e.target.value), value: value, "$variant": variant })), rightElement: React.createElement(Text, { variant: "body1", color: disabled ? 'textDisabled' : 'textPrimary', fontSize: variant === 'small' ? '16px' : '24px' }, currency) }));
};
