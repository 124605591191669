var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '../Box';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
const InputWrapper = styled(Stack) `
  border: 1px solid;
  text-align: left;
  border-radius: ${({ theme }) => theme.radii.input}px;
  background: ${({ theme, $disabled }) => ($disabled ? theme.colors.backgroundPage : theme.colors.backgroundInput)};
  border-color: ${({ theme, $disabled, $active }) => $disabled ? theme.colors.backgroundSecondary : $active ? theme.colors.textSelected : 'transparent'};
  &:focus,
  &:focus-within {
    border-color: var(--fabric-color-focus);
  }
`;
export const InputBox = React.forwardRef((_a, ref) => {
    var { label, secondaryLabel, errorMessage, inputElement, rightElement, disabled, active } = _a, boxProps = __rest(_a, ["label", "secondaryLabel", "errorMessage", "inputElement", "rightElement", "disabled", "active"]);
    const theme = useTheme();
    return (React.createElement(Stack, { gap: 1, width: "100%" },
        React.createElement(InputWrapper, Object.assign({ gap: "4px", px: 2, py: 1, as: "label", "$active": active, "$disabled": disabled }, boxProps, { ref: ref }),
            label && (React.createElement(Text, { variant: "label2", color: disabled ? 'textDisabled' : 'textSecondary' }, label)),
            React.createElement(Stack, null,
                React.createElement(Shelf, null,
                    React.createElement(Box, { flex: "1 1 auto" },
                        React.createElement(Text, { variant: "body1", color: disabled ? 'textDisabled' : 'textPrimary' }, inputElement)),
                    rightElement && (React.createElement(Box, { as: Text, flex: "0 0 auto", display: "flex", style: { color: theme.colors[disabled ? 'textDisabled' : 'textPrimary'] } }, rightElement))),
                secondaryLabel && (React.createElement(Text, { variant: "body3", color: disabled ? 'textDisabled' : 'textSecondary' }, secondaryLabel)))),
        errorMessage && (React.createElement(Box, { px: 2 },
            React.createElement(Text, { variant: "label2", color: "statusCritical" }, errorMessage)))));
});
