import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconCheck(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M20 6L9 17l-5-5", stroke: "currentcolor", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })));
}
SvgIconCheck.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconCheck;
