import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from '../Box';
export const Card = styled(Box)(({ variant = 'default' }) => css({
    bg: 'backgroundPage',
    borderRadius: 'card',
    borderWidth: variant === 'default' ? 1 : 0,
    borderStyle: 'solid',
    borderColor: 'borderSecondary',
    boxShadow: variant === 'interactive' ? 'cardInteractive' : variant === 'overlay' ? 'cardOverlay' : undefined,
    transition: 'box-shadow 100ms ease',
    'a:hover &, button:hover &, a&:hover, button&:hover': {},
    'a:focus-visible &, button:focus-visible &, &:focus-visible, &:focus-within': {
        boxShadow: variant === 'interactive' ? 'cardActive' : undefined,
    },
}));
