import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconDiscourse(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M2 12C2 6.477 6.477 2 12 2v0c5.523 0 10 4.477 10 10v0c0 5.523-4.477 10-10 10H2V12z", stroke: "currentcolor", strokeWidth: 2, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.043 14.596l.956.293a1 1 0 00-.08-.774l-.876.481zM6 18l-.956-.293a1 1 0 001.207 1.261L6 18zm3.644-.944l.432-.902a1 1 0 00-.682-.066l.25.968zM17 11.813c0 2.654-2.16 4.813-4.834 4.813v2c3.77 0 6.834-3.047 6.834-6.813h-2zM12.166 7C14.839 7 17 9.158 17 11.813h2C19 8.047 15.937 5 12.166 5v2zm-4.834 4.813C7.332 9.158 9.492 7 12.166 7V5c-3.771 0-6.834 3.047-6.834 6.813h2zm.587 2.302a4.77 4.77 0 01-.587-2.302h-2c0 1.18.302 2.294.834 3.264l1.753-.962zm-.963 4.178L8 14.889l-1.913-.586-1.042 3.404 1.912.586zm2.438-2.205l-3.645.944.502 1.936 3.644-.944-.501-1.936zm2.772.538c-.751 0-1.46-.17-2.09-.472l-.863 1.804a6.829 6.829 0 002.953.668v-2z", fill: "currentcolor" })));
}
SvgIconDiscourse.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconDiscourse;
