var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import styled, { css } from 'styled-components';
import IconUpload from '../../icon/IconUpload';
import IconX from '../../icon/IconX';
import useControlledState from '../../utils/useControlledState';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Shelf } from '../Shelf';
import { Stack } from '../Stack';
import { Text } from '../Text';
const AddButton = styled(Shelf) `
  color: ${({ theme }) => theme.colors.textDisabled};
  transition: 100ms ease-in-out;
`;
const PreviewPlaceholder = styled(Flex) `
  color: ${({ $active, $disabled, theme }) => $disabled ? theme.colors.textDisabled : $active ? theme.colors.accentPrimary : theme.colors.textPrimary};
  background-color: ${({ $disabled, theme }) => ($disabled ? 'transparent' : theme.colors.backgroundSecondary)};
  transition: border-color 100ms ease-in-out, color 100ms ease-in-out;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  border: 1px dashed
    ${({ theme, $disabled }) => ($disabled ? theme.colors.borderSecondary : theme.colors.borderPrimary)};
`;
const ImageUploadContainer = styled(Stack) `
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme, $disabled }) => ($disabled ? 'transparent' : theme.colors.backgroundInput)};
  box-shadow: ${({ theme, $disabled }) => ($disabled ? `inset 0 0 0 1px ${theme.colors.borderSecondary}` : 'none')};
  border-radius: ${({ theme }) => theme.radii.card}px;
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'initial')};
`;
const UploadButton = styled.button `
  // Absolutely positioned, to avoid nesting the clear button in this one
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  appearance: none;
  cursor: pointer;

  &:focus-visible,
  &:hover {
    & + * ${PreviewPlaceholder} {
      color: ${({ theme }) => theme.colors.accentPrimary};
      border-color: currentcolor;
    }
    & ~ * ${AddButton} {
      color: ${({ theme }) => theme.colors.accentPrimary};
    }
  }

  ${({ $active, theme }) => $active &&
    css `
      & + * ${PreviewPlaceholder} {
        color: ${theme.colors.accentPrimary};
        border-color: currentcolor;
      }
      & ~ * ${AddButton} {
        color: ${({ theme }) => theme.colors.accentPrimary};
      }
    `}
`;
UploadButton.defaultProps = {
    type: 'button',
};
const FormField = styled.input `
  // Visually hidden
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
export const ImageUpload = ({ file: fileProp, onFileChange, validate, errorMessage: errorMessageProp, accept = 'image/*', disabled, label, aspectRatio = '1 / 1', requirements, height, placeholder = 'Not set', }) => {
    const inputRef = React.useRef(null);
    const [curFile, setCurFile] = useControlledState(null, fileProp, onFileChange);
    const [error, setError] = React.useState(null);
    const [fileUrl, setFileUrl] = React.useState('');
    const [dragOver, setDragOver] = React.useState(false);
    const [visible, setVisible] = React.useState(true);
    const observeRef = React.useRef(null);
    const errorMessage = errorMessageProp || error;
    function handleUploadBtnClick() {
        var _a;
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    }
    function handleClear() {
        setError(null);
        setCurFile(null);
        if (fileUrl) {
            URL.revokeObjectURL(fileUrl);
            setFileUrl('');
        }
    }
    function handleNewFile(newFile) {
        return __awaiter(this, void 0, void 0, function* () {
            setError(null);
            if (curFile) {
                handleClear();
            }
            const newError = validate === null || validate === void 0 ? void 0 : validate(newFile);
            if (newError) {
                setError(newError);
                return;
            }
            setCurFile(newFile);
        });
    }
    function handleFileChange(e) {
        const { files: newFiles } = e.target;
        if (newFiles === null || newFiles === void 0 ? void 0 : newFiles.length) {
            const newFile = newFiles[0];
            handleNewFile(newFile);
        }
    }
    function handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(false);
        const { files: newFiles } = e.dataTransfer;
        if (newFiles === null || newFiles === void 0 ? void 0 : newFiles.length) {
            const newFile = newFiles[0];
            handleNewFile(newFile);
        }
    }
    function handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragOver(true);
    }
    function handleDragEnd() {
        setDragOver(false);
    }
    React.useEffect(() => {
        if (!curFile)
            return;
        const url = URL.createObjectURL(curFile);
        setFileUrl(url);
    }, [curFile]);
    function handlePreviewMount(node) {
        if (!node && observeRef.current) {
            observeRef.current.disconnect();
            observeRef.current = null;
            return;
        }
        const child = node === null || node === void 0 ? void 0 : node.querySelector('.large');
        if (node && child) {
            const obs = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    let width;
                    if (entry.contentBoxSize) {
                        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
                        const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                        width = contentBoxSize.inlineSize;
                    }
                    else {
                        width = entry.contentRect.width;
                    }
                    if (width > child.clientWidth + 20) {
                        setVisible(true);
                    }
                    else {
                        setVisible(false);
                    }
                }
            });
            obs.observe(node);
            observeRef.current = obs;
        }
    }
    return (React.createElement(Stack, { gap: 1, width: "100%", height: height, minHeight: "60px" },
        React.createElement(ImageUploadContainer, { "$disabled": disabled, px: 2, py: 1, onDragOver: handleDrag, onDragEnter: handleDrag, onDragEnd: handleDragEnd, onDragLeave: handleDragEnd, onDrop: handleDrop },
            React.createElement(FormField, { type: "file", accept: accept, onChange: handleFileChange, value: "", disabled: disabled, tabIndex: -1, ref: inputRef }),
            React.createElement(Shelf, { gap: 2, height: "100%" },
                React.createElement(UploadButton, { onClick: handleUploadBtnClick, disabled: disabled, "$active": dragOver }),
                React.createElement(Box, { display: "grid", alignItems: "stretch", gridTemplateColumns: "100%", gridTemplateRows: "auto", gridTemplateAreas: "'unit'", flex: "0 0 auto", aspectRatio: aspectRatio, alignSelf: "stretch", ref: handlePreviewMount, minWidth: 34, my: "4px" },
                    React.createElement(PreviewPlaceholder, { "$active": dragOver, "$disabled": disabled, "$visible": !fileUrl, gridArea: "unit", justifySelf: "stretch", alignItems: "center", justifyContent: "center", borderRadius: "input", position: "relative" },
                        React.createElement(IconUpload, { size: "iconSmall", style: { opacity: visible ? 0 : 1 } }),
                        React.createElement(Stack, { gap: 1, alignItems: "center", justifyContent: "center", width: "max-content", position: "absolute", top: "50%", left: "50%", style: { transform: 'translate(-50%, -50%)', pointerEvents: 'none', opacity: visible ? 1 : 0 }, className: "large" },
                            React.createElement(Text, { variant: "label2" }, "Drop file to upload or"),
                            React.createElement(Shelf, { gap: 1 },
                                React.createElement(IconUpload, { size: "iconSmall" }),
                                React.createElement(Text, { variant: "body1", fontWeight: 500, color: "currentColor" }, "Choose image")),
                            requirements && React.createElement(Text, { variant: "label2" }, requirements))),
                    fileUrl && (React.createElement(Box, { gridArea: "unit", justifySelf: "stretch", borderRadius: "input", backgroundImage: `url(${fileUrl})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", backgroundColor: "backgroundSecondary" }))),
                React.createElement(Stack, { gap: "4px", flex: "1 1 auto", minWidth: 0 },
                    label && (React.createElement(Text, { variant: "label2", color: disabled ? 'textDisabled' : 'textSecondary' }, label)),
                    curFile ? (React.createElement(React.Fragment, null,
                        React.createElement(Shelf, { gap: 1 },
                            React.createElement(Text, { variant: "body1", color: disabled ? 'textDisabled' : 'textPrimary', style: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    direction: 'rtl',
                                } }, typeof curFile === 'string' ? curFile : curFile.name),
                            React.createElement(Box, { display: "flex", position: "relative", zIndex: "3", ml: "auto", my: "-10px", mr: "-10px", minWidth: "40px" }, !disabled && React.createElement(Button, { variant: "tertiary", onClick: handleClear, icon: IconX, disabled: disabled }))))) : (React.createElement(React.Fragment, null,
                        React.createElement(AddButton, { gap: 1 },
                            React.createElement(Text, { variant: "body1", color: "inherit" }, placeholder))))))),
        errorMessage && (React.createElement(Box, { px: 2 },
            React.createElement(Text, { variant: "label2", color: "statusCritical" }, errorMessage)))));
};
