var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import css from '@styled-system/css';
import * as React from 'react';
import styled from 'styled-components';
import { Shelf } from '../Shelf';
import { Text } from '../Text';
export const Tabs = ({ selectedIndex, onChange, children }) => {
    return (React.createElement(Shelf, null, React.Children.map(children, (child, index) => React.isValidElement(child)
        ? React.cloneElement(child, {
            active: index === selectedIndex,
            onClick: () => {
                if (selectedIndex !== index)
                    onChange === null || onChange === void 0 ? void 0 : onChange(index);
            },
            tabIndex: index === selectedIndex ? -1 : undefined,
        })
        : child)));
};
const StyledTabsItem = styled.button({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transitionProperty: 'color, box-shadow',
    transitionDuration: '100ms',
    transitionTimingFunction: 'ease-in-out',
    border: 0,
    userSelect: 'none',
    appearance: 'none',
    background: 'transparent',
}, ({ $active, theme }) => {
    return css({
        paddingTop: 1,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        color: $active ? 'textSelected' : 'textPrimary',
        boxShadow: $active ? `inset 0 -2px 0 ${theme.colors.borderSelected}` : 'none',
        '&:hover, &:active, &:focus-visible': {
            color: 'textSelected',
        },
    });
});
export const TabsItem = (_a) => {
    var { children, active, onClick } = _a, rest = __rest(_a, ["children", "active", "onClick"]);
    return (React.createElement(StyledTabsItem, Object.assign({ onClick: onClick, "$active": active }, rest),
        React.createElement(Text, { variant: "interactive1", color: "inherit" }, children)));
};
