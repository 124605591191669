import css from '@styled-system/css';
import * as React from 'react';
import styled from 'styled-components';
import { Text } from '../Text';
const colors = {
    default: 'statusDefault',
    info: 'statusInfo',
    ok: 'statusOk',
    warning: 'statusWarning',
    critical: 'statusCritical',
};
const Wrapper = styled.span((props) => css({
    padding: '0 8px',
    borderColor: props.$color,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '20px',
}));
export const StatusChip = ({ status, children }) => {
    const color = colors[status];
    return (React.createElement(Wrapper, { "$color": color },
        React.createElement(Text, { variant: "label2", lineHeight: "20px", color: color }, children)));
};
