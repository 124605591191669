import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconPools(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { x: 2.014, y: 2.014, width: 19.972, height: 19.972, rx: 3.851, stroke: "currentcolor", strokeWidth: 1.5 }),
        React.createElement("rect", { x: 5.593, y: 5.593, width: 7.446, height: 7.446, rx: 3.723, stroke: "currentcolor", strokeWidth: 1.5 }),
        React.createElement("rect", { x: 12.767, y: 12.767, width: 5.623, height: 5.623, rx: 2.812, stroke: "currentcolor", strokeWidth: 1.534 }),
        React.createElement("rect", { x: 12.75, y: 12.75, width: 5.657, height: 5.657, rx: 2.829, stroke: "currentcolor", strokeWidth: 1.5 })));
}
SvgIconPools.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconPools;
