import * as React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
const Svg = styled.svg((props) => css({
    width: props.size,
    height: props.size,
    color: props.color,
}));
function SvgIconNft(props) {
    return (React.createElement(Svg, Object.assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M7.8 1.808a.4.4 0 01.4 0l5.062 2.923a.4.4 0 01.2.346v5.846a.4.4 0 01-.2.346L8.2 14.192a.4.4 0 01-.4 0l-5.062-2.923a.4.4 0 01-.2-.346V5.077a.4.4 0 01.2-.346L7.8 1.808z", stroke: "currentcolor", strokeWidth: 1.2 }),
        React.createElement("path", { d: "M6.723 6.235v1.972L5.07 6.235H4.56v2.99h.607v-1.92l1.656 1.92h.509V8.063h1.45v-.548h-1.45v-.732h2.683v2.442h.609V6.783h.994v-.548H6.723z", fill: "currentcolor", stroke: "currentcolor", strokeWidth: 0.085 })));
}
SvgIconNft.defaultProps = {
    size: 'iconMedium',
    color: 'currentcolor',
};
export default SvgIconNft;
